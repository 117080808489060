import { computed, createApp, ref } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import ClickOutsideEvent from './utils/ClickOutsideEvent'
import type { Role } from './types/UserInterface'
import Swal from '@/utils/SweetAlert'
import mitt from 'mitt'
import { onIdle } from './utils/OnIdle'
import type { MediaDetails } from './types/MediaInterface'

const app = createApp(App)
app.directive('click-outside', ClickOutsideEvent)
app.config.globalProperties.$api = 'https://dev-api.mozisarok.hu'
/* app.config.globalProperties.$api = 'http://145.236.40.210:3000' */

const token = ref<string | undefined>(localStorage.getItem('token') || undefined)
const role = ref<Role>({ id: 0, role: 'default', formatted: 'Látogató' })

app.config.globalProperties.$token = computed({
  get: () => {
    return token.value
  },
  set: (value: string | undefined) => {
    if (value) {
      localStorage.setItem('token', value)
    } else {
      localStorage.removeItem('token')
    }

    token.value = value
    updateUserRole()
  }
})

app.config.globalProperties.$user = computed(() => {
  if (token.value) {
    const t = JSON.parse(atob(token.value.split('.')[1]))

    if (t.exp < Date.now() / 1000) {
      app.config.globalProperties.$token.value = undefined
    } else {
      validateToken()
      return t
    }
  }

  return undefined
})

app.config.globalProperties.$role = computed({
  get: () => role.value,
  set: (value: Role) => (role.value = value)
})

const updateUserRole = () =>
  onIdle(() => {
    app.config.globalProperties.$role.value = { id: 1, name: 'default', formatted: 'Látogató' }

    if (token.value) {
      axios
        .get(`${app.config.globalProperties.$api}/user/role`, {
          headers: {
            Authorization: `Bearer ${token.value}`
          }
        })
        .then((r) => {
          app.config.globalProperties.$role.value = r.data
        })
        .catch()
    }
  })

const validateToken = () =>
  onIdle(() => {
    if (!token.value) return

    axios
      .get(`${app.config.globalProperties.$api}/auth/validate`, {
        headers: {
          Authorization: `Bearer ${token.value}`
        }
      })
      .catch(() => {
        app.config.globalProperties.$token.value = undefined
      })
  })

function getEasterDates(year: number) {
  let f = Math.floor,
    G = year % 19,
    C = f(year / 100),
    H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
    I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
    J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
    L = I - J,
    month = 3 + f((L + 40) / 44),
    day = L + 28 - 31 * f(month / 4)

  const start = new Date(year, month - 1, day - 7) // Húsvét előtti hét
  const end = new Date(year, month - 1, day + 7) // Húsvét utáni hét
  return { start, end }
}

function getSeasonalDates(year: number) {
  return {
    halloween: {
      start: new Date(year, 9, 20), // Október 20.
      end: new Date(year, 10, 1) // November 1.
    },
    christmas: {
      start: new Date(year, 11, 1), // December 1.
      end: new Date(year, 11, 26) // December 26.
    },
    newYear: {
      start: new Date(year, 11, 27), // December 27.
      end: new Date(year + 1, 0, 5) // Január 5.
    },
    easter: getEasterDates(year)
  }
}

const getCurrentHoliday = () => {
  const today = new Date()
  const year = today.getFullYear()
  const dates = getSeasonalDates(year)

  if (today >= dates.halloween.start && today <= dates.halloween.end) {
    return 'halloween'
  } else if (today >= dates.christmas.start && today <= dates.christmas.end) {
    return 'christmas'
  } else if (today >= dates.newYear.start && today <= dates.newYear.end) {
    return 'newyear'
  } else if (today >= dates.easter.start && today <= dates.easter.end) {
    return 'easter'
  } else {
    return ''
  }
}

function getMediaDetails(mediaId: number, mediaType: 'movie' | 'tv' | 'person'): Promise<MediaDetails | undefined> {
  const resJSON = localStorage.getItem(`${mediaType}-${mediaId}`);
  var res: MediaDetails | undefined = undefined;
  if (resJSON) {
    res = JSON.parse(resJSON);
  }

  if (res && res.last_changed > Date.now() / 1000 - 604800) {
    return Promise.resolve(res);
  }

  return axios
    .get(`${app.config.globalProperties.$api}/media/${mediaType}/${mediaId}`)
    .then((r) => {
      r.data.last_changed = Date.now() / 1000;
      localStorage.setItem(`${mediaType}-${mediaId}`, JSON.stringify(r.data));
      return r.data;
    })
    .catch((error) => {
      /* app.config.globalProperties.$msg('info', 'Ez a ' + (mediaType == "movie" ? 'film' : 'sorozat') + ' nem létezik!') */
      if (error.response && error.response.data && error.response.data.message) {
        app.config.globalProperties.$msg('info', error.response.data.message);
      }
      return undefined;
    });
}

app.config.globalProperties.$transparent = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
app.config.globalProperties.$imageURL = 'https://image.tmdb.org/t/p/'
app.config.globalProperties.$currentHoliday = getCurrentHoliday()
app.config.globalProperties.$getMediaDetails = getMediaDetails

const emitter = mitt()
app.config.globalProperties.$emitter = emitter

if (token.value) {
  updateUserRole()
}

app.provide('global', app.config.globalProperties)

app.use(router).use(VueAxios, axios).use(Swal).mount('#app')
